var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('TTableAdvance',{attrs:{"items":_vm.wallets,"fields":_vm.fields,"store":"accounting.wallets_suppliers","resource":"/accounting/account/supplier","enterable":"","creatable":""},on:{"click-clear-filter":_vm.clearFilter,"click-create":function($event){_vm.showModalCreate = true}},scopedSlots:_vm._u([{key:"id",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessage',{attrs:{"content":item.id}})],1)]}},{key:"wallet",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('SMessageWallet',{attrs:{"data":item}})],1)]}},{key:"balance",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessageMoney',{attrs:{"amount":item.balance,"currency":item.currency_id}})],1)]}},{key:"amount_available_for_payment",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessageMoney',{attrs:{"amount":item.amount_available_for_payment,"currency":item.currency_id}})],1)]}},{key:"deposit",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessageMoney',{attrs:{"amount":item.deposit,"currency":item.currency_id}})],1)]}},{key:"service",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessageMoney',{attrs:{"amount":item.service,"currency":item.currency_id}})],1)]}},{key:"updated_at",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessageDateTime',{attrs:{"content":item.updated_at,"small":""}})],1)]}},{key:"id-filter",fn:function(){return [_c('TInputText',{attrs:{"value":_vm.filter.id},on:{"update:value":[function($event){return _vm.$set(_vm.filter, "id", $event)},_vm.filterChange]}})]},proxy:true},{key:"wallet-filter",fn:function(){return [_c('div',{staticClass:"d-inline-flex w-100"},[_c('SSelectSupplier',{staticClass:"w-100",attrs:{"value":_vm.filter.walletable_id,"noCustomLabel":"","prependAll":""},on:{"update:value":function($event){return _vm.$set(_vm.filter, "walletable_id", $event)},"change":_vm.filterChange}}),_c('SSelectCurrency',{staticClass:"w-75",attrs:{"value":_vm.filter.currency_id},on:{"update:value":function($event){return _vm.$set(_vm.filter, "currency_id", $event)},"change":_vm.filterChange}})],1)]},proxy:true},{key:"updated_at-filter",fn:function(){return [_c('TInputDateTimeRange',{attrs:{"value":_vm.dateRange},on:{"update:value":[function($event){_vm.dateRange=$event},_vm.setCreatedAtFilter]}})]},proxy:true}])}),(_vm.showModalCreate)?_c('Modal',{attrs:{"show":_vm.showModalCreate},on:{"update:show":function($event){_vm.showModalCreate=$event}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }